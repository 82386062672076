import { useEffect, useState } from 'react';
import './ResultsDisplay.css';

const ResultsDisplay = ({results}) => {

    const tagComp = (a, b) => {
        if (a.tag < b.tag) {
            return -1;
        }
        if (a.tag > b.tag) {
            return 1;
        }
        
        return 0;
    }

    const displayCategory = (category) => {
        const items = results.filter((tag) => tag.category == category);
        
        items.sort(tagComp);
        return items;
    }

    const generalTags = () => {
        const items = results.filter((tag) => (tag.category != 'rating' && tag.category != 'species'));
        items.sort(tagComp);
        return items;
    }

    const renderTag = (tag) => {
        return (<div className='tag tag-white' key={tag.tag}>{tag.tag}</div>);
    }

    return (
        <div className='ResultsDisplay d-flex flex-column flex-md-row flex-grow-1'>
            <div className='left-results d-flex flex-column justify-content-between'>
                <div className='display-large-tags'>
                    <h2>Rating</h2>
                    <div className='display-large-tags-inside'>{displayCategory('rating').map((t) => <div className={`${t.tag} tag-large`} key={t.tag}>{t.tag}</div>)}</div>
                </div>
                <div className='display-large-tags'>
                    <h2>Species</h2>
                    <div className='display-large-tags-inside'>{displayCategory('species').map((t) => (<div className='tag-white tag-large' key={t.tag}>{t.tag}</div>))}</div>
                </div>

            </div>
            
            <div className='right-results'>
                <h2>General Tags</h2>
                <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {generalTags().map((t) => (renderTag(t)))}
                </div>
            </div>
        </div>
    );
}

export default ResultsDisplay;