import { useEffect, useState } from 'react';
import { BiImageAdd } from "react-icons/bi";
import { HiUpload } from "react-icons/hi";
import './ImgForm.css';

const allowedFileTypes = ["image/png", "image/jpeg", "image/webp"];

const ImgForm = ({ currImg, setUploadData, parentErr, setParentErr }) => {
    const [image, setImage] = useState(null);
    const [fileSize, setFileSize] = useState(0);
    const [errMsgs, setErrMsgs] = useState([]);

    useEffect(() => {
        let newErrMsgs = [];
        if (maxSize()) {
            newErrMsgs.push('File size is too large (>8MB).')
        }

        if (parentErr) {
            newErrMsgs.push(parentErr);
        }

        setErrMsgs(newErrMsgs);
    }, [fileSize, parentErr])

    useEffect(() => {
        let button = document.querySelector('#form_submit');
        if (currImg && (image === currImg)) {
            button.disabled = true;
        }
        else {
            button.disabled = false;
        }

    }, [image, currImg])


    const handleAddFile = ({ target: { files } }) => {
        if (files) {
            if (files[0]) {
                setImage(files[0]);
                setFileSize(files[0].size);
                setParentErr('');
            }
        }
    }

    const handleFormClick = () => {
        document.querySelector('#image_input').click();
    }

    const handleDrag = (e) => {
        e.preventDefault();
        
        const payload = {
            target: {
                files: []
            }
        };

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...e.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    payload.target.files.push(file);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...e.dataTransfer.files].forEach((file, i) => {
                payload.target.files.push(file);
            });
        }

        console.log(payload);
        handleAddFile(payload);
    }

    function handleDragOver(ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
      }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!image) {
            alert('Please upload an image.');
            return;
        }

        if (maxSize()) {
            alert('Please upload a smaller image.');
            return;
        }

        setUploadData(image);
    }

    const urlImage = (file) => {
        return URL.createObjectURL(file);
    }

    const maxSize = () => {
        return fileSize > 8_000_000;
    }

    return (
        <div className="ImgForm">
            <form id='img-form' className='form-body' onClick={handleFormClick} onSubmit={handleSubmit} onDrop={handleDrag} onDragOver={handleDragOver}>
                <input id='image_input' type='file' accept='.jpg,.png,.jpeg,.webp' onChange={handleAddFile} hidden></input>
                {image ?
                    <img src={urlImage(image)} className='form-image' />
                    :
                    <div className='no-image-display'>
                        <BiImageAdd size={80} />
                        <h3>Drag and drop images here to upload</h3>
                        <p>Or, click to browse images</p>
                    </div>
                }
                <div className='img-add'>
                    <HiUpload />
                </div>
            </form>
            <div className='form-submit'>
                <button id='form_submit' type='submit' form='img-form' className='btn btn-primary'>Submit</button>
                <div className='error-field'>
                    {errMsgs.map((err, ind) => <p key={`err-${ind}`}>{err}</p>)}
                </div>
            </div>
        </div>
    );
}

export default ImgForm;