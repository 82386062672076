import { useEffect, useState } from 'react';
import ImgForm from './components/ImgForm';

import ResultsDisplay from './components/ResultsDisplay';
import ProgressBar from "@ramonak/react-progress-bar";
import { AiOutlineLoading } from "react-icons/ai";
import { FaGithub , FaTwitter } from "react-icons/fa";
import { SiKofi } from "react-icons/si";
import './App.css';

const App = () => {
    const [image, setImage] = useState(null);
    const [md5, setMd5] = useState('');
    const [results, setResults] = useState([]);
    const [progressText, setProgressText] = useState('');
    const [progress, setProgress] = useState(-1);
    const [apiError, setApiError] = useState('');

    const fetchUpload = async (image) => {
        const payload = new FormData();
        payload.append('image', image, image.name);

        const upload = await fetch('/api/image', {
            method: "POST",
            body: payload,
        });
        
        const res = await upload.json();

        if (upload.ok) {
            setMd5(res.file_md5);
        }
        else {
            setApiError(res.error);
        }

    }

    const fetchResult = async (md5) => {
        
        const result = await fetch(`/api/result?file_md5=${md5}`);
        const res = await result.json();

        setResults(res.result);
    }

    useEffect(() => {
        if (image) {
            setMd5('');
            setResults([]);
            setProgress(0);
            setProgressText('Uploading Image...');
            fetchUpload(image);
        }
    }, [image]);

    useEffect(() => {
        if (md5) {
            setProgress(50);
            setProgressText('Running Model...');
            fetchResult(md5);
        }
    }, [md5])
    
    useEffect(() => {
        if (results.length > 0) {
            setProgress(100);
            setProgressText('');
            console.log('Nerd Stats:');
            console.log(results);
        }
    }, [results])

    return (
        <div className="App container">
            <h1>Zenith's Furry Artwork Tagger</h1>
            <ImgForm currImg={image} setUploadData={setImage} parentErr={apiError} setParentErr={setApiError}></ImgForm>

            {(progress >= 0 && progress < 100) ?
                <div className='progress-section container d-flex'>
                    <AiOutlineLoading className='loading-icon' size={40} />
                    <p>{progressText}</p>
                    <ProgressBar completed={progress} bgColor='#3A7DBB' baseBgColor='#F6F5F5' className='progress-bar' />
                </div> 
                :
                <ResultsDisplay results={results} />
                }

            <footer>
                <a href='https://twitter.com/ZenithO_o'><FaTwitter /> @ZenithO_o</a> · <a href='https://github.com/ZenithO-o'><FaGithub /> ZenithO-o</a> · <a href='https://ko-fi.com/zenithoo'><SiKofi /> Zenith</a>
            </footer>
        </div>
    );
}

export default App;
